<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-15"
        :style="{ backgroundImage: `url(${backgroundImage})` }"
      >
        <!--begin: Aside Container -->
        <div class="d-flex flex-row-fluid flex-column justify-content-between">
          <!--begin: Aside header -->
          <a href="#" class="flex-column-auto">
            <img :src="logo" style="width: 5rem" />
          </a>
          <!--end: Aside header -->
          <!--begin: Aside content -->
          <div class="flex-column-fluid d-flex flex-column justify-content-center">
            <h3 class="font-size-h1 mt-10 mb-5 text-white">Willkommen im SKR-Manager! 🚀</h3>
            <!--<p class="font-weight-lighter text-white opacity-80">
              The ultimate Bootstrap, Angular 8, React &amp; VueJS admin theme
              framework for next generation web apps.
            </p>-->
          </div>
          <!--end: Aside content -->
          <!--begin: Aside footer for desktop -->
          <div class="d-none flex-column-auto d-lg-flex justify-content-between mt-15">
            <div class="opacity-70 font-weight-bold text-white">
              © {{ new Date().getFullYear() }} SKR Reisen
            </div>
          </div>
          <!--end: Aside footer for desktop -->
        </div>
        <!--end: Aside Container -->
      </div>
      <!--begin::Aside-->

      <!--begin::Content-->

      <div style="margin: auto">
        <img :src="msLoginButton" @click="signIn" style="cursor: pointer" />
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import '@/assets/sass/pages/login/login-1.scss';
</style>

<script>
import { LOGIN } from '@/core/user/stores/auth.module.js';
import { mapState } from 'vuex';

export default {
  name: 'auth',
  mounted() {},
  methods: {
    async signIn() {
      await this.$store
        .dispatch(LOGIN)
        .then(async response => {
          console.log(response);
        })
        .catch(error => {
          console.error(`error during authentication: ${error}`);
        });
    },
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
    }),
    backgroundImage() {
      return process.env.BASE_URL + 'media/bg/bg-2.jpg';
    },
    logo() {
      return process.env.BASE_URL + 'media/logos/logo-skr.svg';
    },
    msLoginButton() {
      return process.env.BASE_URL + 'media/svg/social-icons/ms-symbollockup_signin_dark.svg';
    },
  },
};
</script>
